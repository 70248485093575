<template>
  <div class="teach">
    <!-- 搜索 -->
    <div class="search_box">
      <div class="mian1200 search">
        <el-autocomplete
          placeholder="请输入院校名称"
          :trigger-on-focus="false"
          :fetch-suggestions="querySearch"
          clearable
          v-model="search"
          @change="List()"
        >
          <template slot="append">
            <div @click="List()">
              <i class="el-icon-search"></i>
              搜索
            </div>
          </template>
          <template slot-scope="{ item }">
            <div class="name">{{ item }}</div>
          </template>
        </el-autocomplete>
      </div>
    </div>
    <div class="mian1200 content">
      <!-- 面包屑 -->
      <breadcrumb :list="this.$route" />
      <!-- 筛选 -->
      <div class="filtrate_box">
        <div class="school border-bottom">
          <span>所属情報</span>
          <div>
             <el-radio-group v-model="school" @change="change()">
                <el-radio-button v-for="(item,key) in school_list" :key="key" :label="item" :value="item"></el-radio-button>
              </el-radio-group>
          </div>
        </div>
        <div class="major">
          <div class="left">
             <div class="school">
              <span>推定分野</span>
              <div>
                <el-radio-group v-model="major" @change="change()">
                    <el-radio-button  label="不限"></el-radio-button>
                  </el-radio-group>
              </div>
            </div>
          </div>
          <div class="right">
             <div class="school" v-for="(item,key) in major_list" :key="key">
              <span>{{item.title}}</span>
              <div>
                <el-radio-group v-model="major" @change="change()">
                    <el-radio-button  v-for="(items,keys) in item.list" :key="keys" :label="items.title"></el-radio-button>
                  </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 教授总数 -->
      <div class="num">
        共找到{{num}}位研究者
      </div>
      <!-- 列表 -->
      <div class="teach_box" v-loading="loading">
        <div class="teach_item" v-for="(item,key) in list" :key="key" @click="goDetaile(item.id)">
          <div class="t1">
            <img :src="item.avatar" alt="">
            <div class="name">{{item.jname}}</div>
            <div class="school">{{item.xname}}{{item.zhiwei?' · '+item.zhiwei:''}}</div>
            <div class="ms">{{item.endtime?item.endtime+'年度':''}}</div>
          </div>
          <div class="t2">
            <div class="major">{{item.major[0]}}</div>
            <div class="subject">{{item.major[1]}}</div>
          </div>
          <div class="t3">
            查看详情
          </div>
        </div>
      </div>
      <div class="fenye" v-if=" teach.length>0">
        <div class="more" v-if="!this.$cookie.get('token')" @click="$store.state.login = true">登录后可获取更多教授资源</div>
        <el-pagination v-else class="riben_pag" background layout="prev, pager, next" :total="num" :page-size='limit' @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/el-breadcrumb/el-breadcrumb.vue";
import {getList,getParameter} from '@/api/teach'
export default {
  data() {
    return {
      list: [],
      loading:false,
      search:'',
      num:0,//总条数
      limit:16,//条数
      page:1,//页数
      school:'不限',//所属情报
      school_list:['不限'],//所属情报筛选项
      major:'不限',//专业
      major_list:[],//专业筛选项
      teach:[
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
        {
          img:"http://crm.hi-daas.com/querysome.action?m=viewImg&fileid=16202040850444CcNDuiR!8b7644726d0d29f2c3d8ea5d8eb842f4",
          name:'小川范子',
          school:'京都大学·教授',
          ms:'2021年度(R.3)',
          major:"理学/化学",
          subject:'医歯薬学/臨床系内科学'
        },
      ]
    };
  },
  components: { breadcrumb },
  async mounted() {
    console.log(this.$route.query)
    if(this.$route.query.school){
      this.school = this.$route.query.school
    }
    await this.Parameter()
    await this.List()
  },
  methods:{
    goDetaile(id){
      let path = this.$router.resolve({ path: '/teachdetai', query: {  id } })
      window.open(path.href, '_blank')
    },
    querySearch(queryString, cb) {
        // var restaurants = this.restaurants;
        // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb([]);
      },
    pageChange(e){
      console.log(e)
      this.page = e
      this.List()
    },
    change(){
      this.page = 1
      this.search = ''
      this.List()
    },
    //获取列表参数
    async Parameter(){
      await getParameter().then(res=>{
        console.log(res)
        this.school_list=this.school_list.concat(res.data.school_list)
        this.major_list = res.data.major_list

      })
    },
    //获取教授列表
    async List(){
      this.loading = true
      await getList({
        limit:this.limit,
        page:this.page,
        search:this.search,
        school:this.school,
        major:this.major
      }).then(res=>{
        this.loading = false
        console.log(res)
        this.list = res.data.list
        this.num = res.data.total
      })
    }
  }
};
</script>
<style scoped lang="scss">
.teach {
  background: #f5f5f5;
  padding-bottom: 40px;
  font-family: "japanFont";
}
.mian1200 {
  width: 1200px;
  margin: 0 auto;
}
//搜索
.search_box {
  width: 100%;
  height: 84px;
  background: #ffffff;
  margin-top: 12px;
  .search {
    display: flex;
    padding: 20px 0px;
    /deep/ .el-autocomplete {
      width: 100%;
      .el-input__inner {
        background: #fafafa;
        // border-right: none;
        &.el-input__inner:focus {
          border-color: #d99d66;
          // border-right: none;
        }
      }
      .el-input-group__append {
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        color: #935d14;
        cursor: pointer;
      }
    }
  }
}
.content {
  .filtrate_box {
    background: #ffffff;
    border-radius: 8px;
    padding: 0px 25px;
    box-sizing: border-box;
    .school {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      >span{
        // width: 80px;
        text-align: center;
        flex-shrink: 0;
        margin-right: 14px;
        font-size: 14px;
        color: #999999;
      }
      >div{
        flex: 1;
        //单选框
        /deep/ .el-radio-group {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .el-radio-button {
            outline:none !important;
            box-shadow: none !important;
            .el-radio-button__inner {
              // width: 80px;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              color: #3A3A3A;
              border: none;
              margin: 0px 4px;
              border-radius: 0px;
              box-sizing: border-box;
              outline:none;
              padding: 4px 10px;
              border-radius: 2px;
            }
          }
          .is-active {
            color: #d99d66;
            .el-radio-button__inner {
              color: #FFFFFF;
              background: none;
              box-shadow: none;
              background: #60452F;
            }
          }
        }
      }
    }
    .major{
      display: flex;
      .left{
        width: 135px;
      }
      .right{
        flex: 1;
      }
    }
    .border-bottom{
      margin-bottom: 10px;
      padding:  15px 0;
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .num{
    font-size: 20px;
    color: #333333;
    padding: 20px 0px;
    font-family: HanaMinA;
  }
  .teach_box{
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    .teach_item{
      width: 285px;
      margin-right: 20px;
      height: 371px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;
      transition: all 0.3s;
      overflow: hidden;
      cursor: pointer;
      bottom:0px;
      .t1{
        height: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px dashed #D8D8D8;
         transition: all 0.3s;
        img{
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
          margin-top: 30px;
        }
        .name{
          font-size: 18px;
          color: #231716;
          margin-top: 20px;
        }
        .school{
          font-size: 16px;
          color: #333333;
          margin-top: 10px;
        }
        .ms{
          margin-top: 10px;
          font-size: 16px;
          color: #60452F;
        }
      }
      .t2{
        height: 88px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F0F0F0;
        font-size: 16px;
        color: #3A3A3A;
         transition: all 0.3s;
        .subject{
          margin-top: 6px;
        }
      }
      .t3{
        height: 51px;
        font-size: 16px;
        color: #999999;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        background: white;
        transition: all 0.3s;
      }
    }
    .teach_item:hover{
      bottom: 10px;
      .t1{
        img{
          border: 3px solid #E7D698;
        }
      }
      .t3{
        color: white;
        background: linear-gradient(90deg, #E7D698 0%, #CAAD5F 100%);
      }
    }
  }
}
//分页器
.fenye {
  height: 106px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .more{
    font-size: 16px;
    font-weight: 400;
    color: #1890FF;
    border-radius: 4px;
    border: 1px solid rgba(24, 144, 255, 0.3);
    padding: 10px 15px;
    cursor: pointer;
  }
}
//分页器样式
/deep/ .riben_pag {
  .btn-prev,
  .btn-next {
    border: 1px solid #EDEDED;
    background: white;
    border-radius: 4px;
  }
  .el-pager li {
    border-radius: 4px;
    border: 1px solid #ededed;
    background: white;
    font-weight: 400;
    color: #999999;
  }
  .el-pager li:hover {
    background: #60452F;
    color: white !important;
    opacity: 0.8;
  }
  .el-pager li.active {
    background: #60452F !important;
    color: white;
  }
}
</style>
