<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="mb">
    <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
    <el-breadcrumb-item
      v-for="(item, key) in breadList"
      :key="key"
      :to="{ path: item.path }"
      >{{ item.meta.title }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<script>
export default {
  props: ["list"],
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  watch: {
    $route() {
      // console.log(this.$route)
      this.getBreadcrumb();
    },
  },
  methods: {
    isHome(route) {
      return route.name === "home";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      // console.log(matched)
      //如果不是首页
      if (!this.isHome(matched[0])) {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      }
      this.breadList = matched;
      
    },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>

<style lang="scss" scoped>
.mb {
  padding: 15px 0px;
}
</style>
