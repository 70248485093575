import request from '@/utils/axios'
//教授详情
export function getDetails (params) {
  return request({
    url: '/api/teach/info',
    method: 'get',
    params
  })
}
//教授详情-教授推荐
export function getRecommend (params) {
  return request({
    url: '/api/teach/recommend',
    method: 'get',
    params
  })
}
//教授列表
export function getList (data) {
  return request({
    url: '/api/teach/list',
    method: 'post',
    data
  })
}
//教授列表参数
export function getParameter (data) {
  return request({
    url: '/api/teach/parameter',
    method: 'post',
    data
  })
}